import { array, boolean, field, number, string, succeed } from "jsonous"; 
 
export const pollChecklistTemplate = succeed({}) 
  .assign("id", field("id", number)) 
  .assign("nameRu", field("nameRu", string)) 
  .assign("nameEn", field("nameEn", string)) 
  .assign( 
    "checklistPollSection", 
    field( 
      "checklistPollSection", 
      array( 
        succeed({}) 
          .assign("id", field("id", number)) 
          .assign("titleRu", field("titleRu", string)) 
          .assign("titleEn", field("titleEn", string)) 
          .assign("isRequired", field("isRequired", boolean)) 
          .assign("checkpoints", field("checkpoints", array(succeed({}).assign("ru", field("nameRu", string)).assign("en", field("nameEn", string))))) 
      ) 
    ), 
  )
  .map((template) => ({ 
    id: String(template.id), 
    checklistPollSection: template.checklistPollSection, 
    name: { 
      en: template.nameEn, 
      ru: template.nameRu, 
    }, 
  }));