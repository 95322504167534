import qs from "qs";
import { Inject, Service } from "typedi";

import {
  checkListTableDecoder,
  emptyDecoder,
  identityValueDecoder,
  tableDataDecoder,
  yaInspectorChecklistDecoder,
} from "../decoders";
import {
  IGetYaInspectorChecklistsFilters,
  ITableDto,
  IYaInspectorChecklist,
  IYaInspectorChecklistPostDto,
  Pagination,
} from "../interfaces";
import { METHODS, RequestManager } from "../requestManager";

@Service()
export class YaInspectorCheckListApi {
  @Inject()
  private requestManager!: RequestManager;

  public async getCheckListsRequest({
    startDate,
    endDate,
    department,
    whoCompleted,
    pageIndex,
    pageSize,
  }: IGetYaInspectorChecklistsFilters & Pagination): Promise<ITableDto<IYaInspectorChecklist>> {
    const query = qs.stringify(
      {
        sort: "controlDate:desc",
        filters: {
          department: {
            id: {
              $eq: department,
            },
          },
          whoCompleted: {
            id: {
              $eq: whoCompleted,
            },
          },
          controlDate: {
            $gte: startDate,
            $lte: endDate,
          },
        },
        populate: [
          "department",
          "department.client",
          "whoCompleted",
          "checklistPollSection",
          "checklistPollSection.checkpoints",
          "checklistPollSection.checkpoints.responsiblePerson",
          "checklistPollSection.checkpoints.photos",
        ],
        pagination: {
          pageSize,
          page: pageIndex,
        },
      },
      { skipNulls: true },
    );
    return await this.requestManager.createRequest({
      url: `/api/polls?${query}`,
      method: METHODS.GET,
      serverDataDecoder: tableDataDecoder(checkListTableDecoder),
    })();
  }

  public async getCheckListsReportRequest({
    startDate,
    endDate,
    pageIndex,
    pageSize,
  }: IGetYaInspectorChecklistsFilters & Pagination): Promise<ITableDto<IYaInspectorChecklist>> {
    const query = qs.stringify(
      {
        sort: "controlDate:desc",
        filters: {
          controlDate: {
            $gte: startDate,
            $lte: endDate,
          },
        },
        populate: [
          "department",
          "department.client",
          "whoCompleted",
          "checklistPollSection",
          "checklistPollSection.checkpoints",
          "checklistPollSection.checkpoints.responsiblePerson",
          "checklistPollSection.checkpoints.photos",
        ],
        pagination: {
          pageSize,
          page: pageIndex,
        },
      },
      { skipNulls: true },
    );
    return await this.requestManager.createRequest({
      url: `/api/polls?${query}`,
      method: METHODS.GET,
      serverDataDecoder: tableDataDecoder(checkListTableDecoder),
    })();
  }

  public async getChecklistRequest(id: any) {
    const query = qs.stringify({
      populate: [
        "department",
        "department.client",
        "department.address",
        "whoCompleted",
        "checklistPollSection",
        "checklistPollSection.checkpoints",
        "checklistPollSection.checkpoints.responsiblePerson",
        "checklistPollSection.checkpoints.photos",
      ],
    });
    return await this.requestManager.createRequest({
      url: `/api/polls/${id}?${query}`,
      method: METHODS.GET,
      requestConfig: {},
      serverDataDecoder: yaInspectorChecklistDecoder,
    })();
  }

  public async postChecklistRequest(payload: IYaInspectorChecklistPostDto) {
    return await this.requestManager.createRequest({
      url: "/api/polls",
      method: METHODS.POST,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(payload);
  }

  public async putUpdateChecklistRequest(payload: IYaInspectorChecklistPostDto, id: string) {
    return await this.requestManager.createRequest({
      url: `/api/polls/${id}`,
      method: METHODS.PUT,
      requestConfig: {},
      serverDataDecoder: emptyDecoder,
    })(payload);
  }

  public async deleteChecklistRequest(id: string) {
    return await this.requestManager.createRequest({
      url: `/api/polls/${id}`,
      method: METHODS.DELETE,
      requestConfig: {},
      serverDataDecoder: identityValueDecoder,
    })();
  }
}
