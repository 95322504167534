import { array, boolean, field, number, string, succeed } from "jsonous";

import { commonUserDecoder, departmentCommonDecoder, photosDecoder } from "./common";
import { entityDecoder, orDefaultDecoder, tableEntityDecoder } from "./utils";

const checkpointDecoder = succeed({})
  .assign("id", field("id", number))
  .assign("isOk", field("isOk", orDefaultDecoder(boolean, null)))
  .assign("textRu", field("textRu", string))
  .assign("textEn", field("textEn", string))
  .assign("commentary", field("commentary", orDefaultDecoder(string, null)))
  .assign("deadlineDate", field("deadlineDate", orDefaultDecoder(string, null)))
  .assign("responsiblePerson", field("responsiblePerson", orDefaultDecoder(commonUserDecoder, null)))
  .assign("photos", field("photos", photosDecoder))
  .map((checkpoint) => ({
    ...checkpoint,
    id: String(checkpoint.id),
    text: {
      ru: checkpoint.textRu,
      en: checkpoint.textEn,
    },
    responsiblePerson: checkpoint.responsiblePerson
      ? {
          id: String(checkpoint.responsiblePerson.id),
          ru: `${checkpoint.responsiblePerson.lastNameRu} ${checkpoint.responsiblePerson.firstNameRu} ${
            checkpoint.responsiblePerson.middleNameRu ? checkpoint.responsiblePerson.middleNameRu : ""
          }`,
          en: `${checkpoint.responsiblePerson.lastNameEn} ${checkpoint.responsiblePerson.firstNameEn} ${
            checkpoint.responsiblePerson.middleNameEn ? checkpoint.responsiblePerson.middleNameEn : ""
          }`,
        }
      : null,
  }));

const checkListAttributesDecoder = succeed({})
  .assign("checklistNumber", field("checklistNumber", string))
  .assign("controlDate", field("controlDate", string))
  .assign("department", field("department", departmentCommonDecoder))
  .assign("whoCompleted", field("whoCompleted", commonUserDecoder))
  .assign( 
    "checklistPollSection", 
    field( 
      "checklistPollSection", 
      array( 
        succeed({}) 
          .assign("id", field("id", number.map(String))) 
          .assign("titleRu", field("titleRu", string)) 
          .assign("titleEn", field("titleEn", string)) 
          .assign("isRequired", field("isRequired", boolean)) 
          .assign("checkpoints", field("checkpoints", array(checkpointDecoder))) 
      ) 
    ), 
  )
  .assign("nameRu", field("nameRu", string))
  .assign("nameEn", field("nameEn", string))
  .map((checklist) => ({
    ...checklist,
    name: {
      ru: checklist.nameRu,
      en: checklist.nameEn,
    },
    whoCompleted: {
      id: String(checklist.whoCompleted.id),
      ru: `${checklist.whoCompleted.lastNameRu} ${checklist.whoCompleted.firstNameRu} ${
        checklist.whoCompleted.middleNameRu ? checklist.whoCompleted.middleNameRu : ""
      }`,
      en: `${checklist.whoCompleted.lastNameEn} ${checklist.whoCompleted.firstNameEn} ${
        checklist.whoCompleted.middleNameEn ? checklist.whoCompleted.middleNameEn : ""
      }`,
    },
  }));

export const checkListTableDecoder = tableEntityDecoder(checkListAttributesDecoder);

export const yaInspectorChecklistDecoder = entityDecoder(checkListAttributesDecoder);
